var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "item_type_phone" }, [
    _c(
      "div",
      { staticClass: "phone_number_area" },
      [
        _vm._l(["pc", "mobi"], function (device) {
          return _vm.item.openAreaCode
            ? _c("select-component", {
                key: device,
                staticClass: "area_code",
                class: "area_code_" + device,
                attrs: {
                  list: _vm.mobileCtNameList,
                  value: _vm.areaCode,
                  placeholder: "区号",
                  "content-key": device === "pc" ? "pcText" : "ctCode",
                },
                on: {
                  input: function ($event) {
                    return _vm.selectAreaCode($event)
                  },
                },
              })
            : _vm._e()
        }),
        _vm._v(" "),
        _c("div", { staticClass: "phone_number_wrap" }, [
          _vm.requiredIcon
            ? _c("span", { staticClass: "required_icon" }, [_vm._v("*")])
            : _vm._e(),
          _vm._v(" "),
          _c("input", {
            staticClass: "input_style jz_focus jz_theme_focus_border_color",
            style: _vm.itemInputStyle,
            attrs: {
              maxlength: "11",
              onkeypress: "javascript:return Fai.isPhoneNumberKey(event);",
              placeholder: _vm.item.placeholder,
            },
            domProps: { value: _vm.phoneNumber },
            on: { input: _vm.inputPhoneNumber },
          }),
        ]),
      ],
      2
    ),
    _vm._v(" "),
    _vm.smsVerifyCurr && !_vm.inMemberDetailEdit
      ? _c("div", { staticClass: "phone_validator_code_area" }, [
          _c("input", {
            staticClass: "input_style jz_focus jz_theme_focus_border_color",
            style: _vm.itemInputStyle,
            attrs: { placeholder: _vm.memberDialogEnterCode },
            domProps: { value: _vm.phoneValidateCode },
            on: { input: _vm.onPhoneValidateCodeClick },
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "phone_validator_code_btn jz_theme_bg_color jz_theme_font_color",
              class: _vm.codeBtnClass,
              on: { click: _vm.onPhoneValidatorClick },
            },
            [_vm._v(_vm._s(_vm.photoneValidatorText))]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }