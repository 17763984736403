var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "m_member_signup" }, [
    _c(
      "form",
      { staticClass: "m_member_signup_form" },
      [
        _vm.data.signUpbyMobile
          ? [
              _c("div", { staticClass: "m_member_signup_item" }, [
                _c("label", { staticClass: "m_member_signup_label" }, [
                  _c(
                    "div",
                    {
                      staticClass: "m_member_signup_text",
                      attrs: { title: _vm.LS.fastLoginMobile },
                    },
                    [_vm._v(_vm._s(_vm.LS.fastLoginMobile))]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "m_member_signup_suffix" }, [
                    _vm._v(_vm._s(_vm.data.conlon)),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "m_member_signup_control m_member_signup_required",
                  },
                  [
                    _c("div", { staticClass: "m_member_signup_box" }, [
                      _c("input", {
                        staticClass:
                          "m_member_signup_input jz_theme_focus_border_color J_userAddItem J_isCheckUAI",
                        attrs: {
                          id: "mobile",
                          type: "text",
                          name: _vm.LS.fastLoginMobile,
                          placeholder: _vm.LS.fastLoginMobile,
                          maxlength: "11",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        staticClass:
                          "c_select m_member_signup_mobile J_userAddItem",
                        attrs: {
                          id: "mobileCt",
                          name: _vm.LS.mobi_mobileAreaCode,
                        },
                      },
                      _vm._l(
                        _vm.data.mobileCtNameList,
                        function (mobileCtName) {
                          return _c(
                            "option",
                            { domProps: { value: mobileCtName.ctName } },
                            [
                              _vm._v(
                                _vm._s(
                                  mobileCtName.ctShowName + mobileCtName.ctCode
                                )
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                    _vm._v(" "),
                    _c("i", { staticClass: "c_right_arrow" }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "m_member_signup_item" }, [
                _c("label", { staticClass: "m_member_signup_label" }, [
                  _c(
                    "div",
                    {
                      staticClass: "m_member_signup_text",
                      attrs: { title: _vm.LS.loginDialogMsgCode },
                    },
                    [_vm._v(_vm._s(_vm.LS.loginDialogMsgCode))]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "m_member_signup_suffix" }, [
                    _vm._v(_vm._s(_vm.data.conlon)),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "m_member_signup_control m_member_signup_required",
                  },
                  [
                    _c("div", { staticClass: "m_member_signup_box" }, [
                      _c("input", {
                        staticClass:
                          "m_member_signup_input jz_theme_focus_border_color J_userAddItem J_isCheckUAI",
                        attrs: {
                          id: "messageAuthCode",
                          type: "text",
                          name: _vm.LS.loginDialogMsgCode,
                          placeholder: _vm.LS.loginDialogMsgCode,
                          maxlength: _vm.data.msgMaxlen,
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "m_member_signup_msg J_memberSignupMsg",
                          attrs: {
                            title: _vm.LS.js_getMobileMsmCode,
                            "data-text": _vm.LS.js_getMobileMsmCode,
                          },
                          on: { click: _vm.authCodeClick },
                        },
                        [_vm._v(_vm._s(_vm.LS.js_getMobileMsmCode))]
                      ),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("member-signup-captcha", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.data.showCaptcha,
                    expression: "data.showCaptcha",
                  },
                ],
                attrs: {
                  id: _vm.module.id,
                  conlon: _vm.data.conlon,
                  "captcha-maxlen": _vm.data.captchaMaxlen,
                },
              }),
            ]
          : _vm.data.signUpbyMail
          ? [
              _c("div", { staticClass: "m_member_signup_item" }, [
                _c("label", { staticClass: "m_member_signup_label" }, [
                  _c(
                    "div",
                    {
                      staticClass: "m_member_signup_text",
                      attrs: { title: _vm.LS.g_email },
                    },
                    [_vm._v(_vm._s(_vm.LS.g_email))]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "m_member_signup_suffix" }, [
                    _vm._v(_vm._s(_vm.data.conlon)),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "m_member_signup_control m_member_signup_required",
                  },
                  [
                    _c("div", { staticClass: "m_member_signup_box" }, [
                      _c("input", {
                        staticClass:
                          "m_member_signup_input jz_theme_focus_border_color J_isCheckUAI J_userAddItem",
                        attrs: {
                          id: "email",
                          type: "text",
                          name: _vm.LS.g_email,
                          placeholder: _vm.LS.g_email,
                          maxlength: "50",
                        },
                      }),
                    ]),
                  ]
                ),
              ]),
            ]
          : [
              _c("div", { staticClass: "m_member_signup_item" }, [
                _c("label", { staticClass: "m_member_signup_label" }, [
                  _c(
                    "div",
                    {
                      staticClass: "m_member_signup_text",
                      attrs: { title: _vm.LS.memberLogin_acct_new },
                    },
                    [_vm._v(_vm._s(_vm.LS.memberLogin_acct_new))]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "m_member_signup_suffix" }, [
                    _vm._v(_vm._s(_vm.data.conlon)),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "m_member_signup_control m_member_signup_required",
                  },
                  [
                    _c("div", { staticClass: "m_member_signup_box" }, [
                      _c("input", {
                        staticClass:
                          "m_member_signup_input jz_theme_focus_border_color J_isCheckUAI",
                        attrs: {
                          id: "memberSignupAcct",
                          type: "text",
                          placeholder: _vm.LS.memberLogin_acct_new,
                          maxlength: _vm.data.accMaxLen,
                        },
                      }),
                    ]),
                  ]
                ),
              ]),
            ],
        _vm._v(" "),
        _c("div", { staticClass: "m_member_signup_item" }, [
          _c("label", { staticClass: "m_member_signup_label" }, [
            _c(
              "div",
              {
                staticClass: "m_member_signup_text",
                attrs: { title: _vm.LS.memberLogin_pwd_new },
              },
              [_vm._v(_vm._s(_vm.LS.memberLogin_pwd_new))]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "m_member_signup_suffix" }, [
              _vm._v(_vm._s(_vm.data.conlon)),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "m_member_signup_control m_member_signup_required" },
            [
              _c("div", { staticClass: "m_member_signup_box" }, [
                _c("input", {
                  staticClass:
                    "m_member_signup_input jz_theme_focus_border_color J_isCheckUAI",
                  attrs: {
                    id: "memberSignupPwd",
                    type: "password",
                    autocomplete: "off",
                    placeholder: _vm.LS.memberLogin_pwd_new,
                    maxlength: _vm.data.accMaxLen,
                  },
                }),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "m_member_signup_item" }, [
          _c("label", { staticClass: "m_member_signup_label" }, [
            _c(
              "div",
              {
                staticClass: "m_member_signup_text",
                attrs: { title: _vm.LS.mobi_memberSignup_retypePassword },
              },
              [_vm._v(_vm._s(_vm.LS.mobi_memberSignup_retypePassword))]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "m_member_signup_suffix" }, [
              _vm._v(_vm._s(_vm.data.conlon)),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "m_member_signup_control m_member_signup_required" },
            [
              _c("div", { staticClass: "m_member_signup_box" }, [
                _c("input", {
                  staticClass:
                    "m_member_signup_input jz_theme_focus_border_color J_isCheckUAI",
                  attrs: {
                    id: "memberSignupRepwd",
                    type: "password",
                    autocomplete: "off",
                    placeholder: _vm.LS.mobi_memberSignup_retypePassword,
                    maxlength: _vm.data.accMaxLen,
                  },
                }),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _vm._l(_vm.displayList, function (displayItem) {
          return _c(
            "div",
            {
              staticClass: "m_member_signup_item",
              class: _vm.getFileUploadItemClass(displayItem),
            },
            [
              _c("label", { staticClass: "m_member_signup_label" }, [
                _c(
                  "div",
                  {
                    staticClass: "m_member_signup_text",
                    attrs: { title: displayItem.name },
                  },
                  [_vm._v(_vm._s(displayItem.name))]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "m_member_signup_suffix" }, [
                  _vm._v(_vm._s(_vm.data.conlon)),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "m_member_signup_control",
                  class: {
                    m_member_signup_required: _vm.getRequired(displayItem),
                  },
                },
                [
                  displayItem.pt == 1
                    ? [
                        _c(
                          "select",
                          {
                            staticClass:
                              "c_select m_member_signup_select J_userAddItem",
                            attrs: {
                              id: displayItem.fieldKey,
                              name: displayItem.name,
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v(_vm._s(_vm.LS.siteFormPleaseSelect)),
                            ]),
                            _vm._v(" "),
                            _vm._l(displayItem.pc.split("\n"), function (item) {
                              return _c(
                                "option",
                                { domProps: { value: item } },
                                [_vm._v(_vm._s(item))]
                              )
                            }),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("i", { staticClass: "c_right_arrow" }),
                      ]
                    : displayItem.pt == 0
                    ? [
                        _c("div", { staticClass: "m_member_signup_box" }, [
                          _c("input", {
                            staticClass:
                              "m_member_signup_input jz_theme_focus_border_color J_userAddItem",
                            class: {
                              J_isCheckUAI: _vm.getRequired(displayItem),
                            },
                            attrs: {
                              id: displayItem.fieldKey,
                              type: "text",
                              name: displayItem.name,
                              placeholder: _vm.getPlaceholder(displayItem),
                              maxlength: _vm.getItemLimit(displayItem),
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        "mobile" == displayItem.fieldKey
                          ? _c(
                              "select",
                              {
                                staticClass: "c_select m_member_signup_mobile",
                                attrs: {
                                  id: "mobileCt",
                                  name: displayItem.name,
                                },
                              },
                              _vm._l(
                                _vm.data.mobileCtNameList,
                                function (mobileCtName) {
                                  return _c(
                                    "option",
                                    {
                                      domProps: { value: mobileCtName.ctName },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            mobileCtName.ctShowName +
                                              mobileCtName.ctCode
                                          ) +
                                          "\n                        "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]
                    : displayItem.pt == 2
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "member_filewrap",
                            attrs: { id: "member_filewrap-" + displayItem.id },
                          },
                          [
                            _c("input", {
                              class: _vm.getMustClass(displayItem),
                              attrs: {
                                id: displayItem.fieldKey,
                                name: displayItem.id,
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "member_addfile_wrap" }, [
                              _c("div", { staticClass: "member_addfile_btn" }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.LS.js_add_file)),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "member_addfile_tips" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.getFileUploadTips(displayItem))
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "member_filedetail_wrap" },
                              [
                                _c("span", { staticClass: "member_file_name" }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "member_filemeta_wrap" },
                                  [
                                    _c("span", {
                                      staticClass: "member_file_size",
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        ref: "nofllow",
                                        refInFor: true,
                                        staticClass: "member_file_preview_link",
                                        attrs: {
                                          href: "javascript:void(0);",
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "member_file_preview",
                                          },
                                          [
                                            _c("use", {
                                              attrs: {
                                                "xlink:href":
                                                  "#icon_file_preview",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        ref: "nofllow",
                                        refInFor: true,
                                        staticClass:
                                          "member_file_download_link",
                                        attrs: {
                                          href: "javascript:void(0);",
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "member_file_download",
                                          },
                                          [
                                            _c("use", {
                                              attrs: {
                                                "xlink:href": "#icon_download",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]
          )
        }),
        _vm._v(" "),
        !_vm.noRemark
          ? _c("div", { staticClass: "m_member_signup_item" }, [
              _c("label", { staticClass: "m_member_signup_label" }, [
                _c(
                  "div",
                  {
                    staticClass: "m_member_signup_text",
                    attrs: { title: _vm.LS.msgBoardSaid },
                  },
                  [_vm._v(_vm._s(_vm.LS.msgBoardSaid))]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "m_member_signup_suffix" }, [
                  _vm._v(_vm._s(_vm.data.conlon)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "m_member_signup_control" }, [
                _c("div", { staticClass: "m_member_signup_box" }, [
                  _c("input", {
                    staticClass:
                      "m_member_signup_input jz_theme_focus_border_color",
                    attrs: {
                      id: "memberSignupRemark",
                      type: "text",
                      placeholder: _vm.LS.msgBoardSaid,
                      maxlength: 200,
                    },
                  }),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.data.signUpbyMobile
          ? _c("member-signup-captcha", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.data.showCaptcha,
                  expression: "data.showCaptcha",
                },
              ],
              attrs: {
                id: _vm.module.id,
                conlon: _vm.data.conlon,
                "captcha-maxlen": _vm.data.captchaMaxlen,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.data.proOpen
          ? _c("div", { staticClass: "m_member_signup_protocal" }, [
              _c("label", { staticClass: "m_member_signup_agree" }, [
                _c("input", {
                  staticClass: "m_member_signup_checkbox c_checkbox",
                  attrs: { id: "memberAgreePro", type: "checkbox" },
                }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.LS.memberProtocol_labelMsg))]),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  ref: "signupProtocal",
                  staticClass:
                    "J_memberSignupProtocal m_member_signup_protocal_anchor cursor-pointer",
                  on: { click: _vm.protocalClick },
                },
                [_vm._v("《" + _vm._s(_vm.data.proName) + "》")]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "m_member_signup_register jz_theme_bg_color",
            attrs: { id: "memberSignupButton", type: "button" },
            on: { click: _vm.signupClick },
          },
          [_vm._v(_vm._s(_vm.LS.memberReg))]
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }